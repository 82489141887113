.footer-container{
    padding: 5vh 10vw;
    background: radial-gradient(rgb(65, 63, 63), rgba(39, 36, 36, 0.911));
    color: white;
}

#footer-logo{
    width: 20vw;
    margin: 2vh 0;
    margin-bottom: 5vh;
}

.footer-sm-logo{
    width: 5vh;
    margin-bottom: 5vh;
    cursor: pointer;
}
.footer-sm-logo:hover{
    transform: scale(1.05);
    transition: all 0.2s linear;
}
.footer-list-title{
    font-size: 20px;
    font-weight: bold;
    padding: 2vh;
}
.footer-list{
    text-decoration: underline;
    padding: 1vh;
    cursor: pointer;
}
.footer-list:hover{
    font-weight: bold;
}

#footer-btn{
    padding: 3vh 5vh;
    border-radius: 0;
    border-color: black;
    margin: 5vh 0;
}

.footer-brands-logo{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 10px 2px black;
    margin-top: 2vh;
}

.brandsLogos{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#cookiesPolicy-btn{
    text-align: center;
    cursor: pointer;

}

#cookiesPolicy-btn:hover{
    text-decoration: underline;
}


@media only screen and (max-width: 768px){
    .brandsLogos{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .footer-brands-logo{
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 10px 2px black;
        margin-top: 2vh;
    }
}
.prices-groups{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.service-price-title{
    margin-top:6vh;
    margin-bottom: 0vh;
    cursor:default;
}
.service-price-description{
    margin-top: 0.5vh;
    cursor:default;
}
.service-price-description:hover{
    text-decoration: underline;
    background-color: rgba(5, 5, 5, 0.075);
    font-weight: bolder;
}


@media only screen and (max-width: 950px){
    .service-price-description{
        margin-top: 2vh;
        cursor:default;
    }
    .service-price-title{
        margin-top: 6vh;
    }
}
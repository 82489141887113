.category-name{
    cursor: pointer;
}
.category-name:hover{
    text-decoration: underline;
}

.gallery-pic{
    width: 100%;
    box-shadow: 0 2px 10px 2px black;
    cursor: pointer;
    margin: 1vh;
}
.gallery-pic:hover{
    transform: scale(1.05);
    filter: brightness(0.8);
    transition: all 0.2s linear;
}

li{
    text-decoration: none;
    list-style: none;
    text-align: center;
}

.modal-pic{
    width: 100%
}


.modal-gallery-logo{
    width: 60px;
    margin-right: 1vw;
}

.no-pics-div{
    text-align: center;
}

#no-gallery-instagram{
    width: 50px;
}

#no-gallery-instagram:hover{
    transform: scale(1.05);
}
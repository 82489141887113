
.service-banner-left-box{
    min-height: 100%;
    background: linear-gradient(rgb(241, 222, 111), rgba(151, 129, 3, 0.774));
    background: linear-gradient(rgb(241, 238, 238), rgb(221, 218, 218));
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    padding: 0 10%;
}


.banner-img-row{
    display: flex;
    justify-content: center;
}

.sb-col-1{
    background-image: url('../../media/images/ServicesBanner/leikkaukset.jpg');
}

.sb-col-2{
    background-image: url('../../media/images/ServicesBanner/varjaykset.jpeg');
    background-position: center;
}

.sb-col-3{
    background-image: url('../../media/images/ServicesBanner/kampauksetjameikit.jpg');
	background-position: center;
}

.sb-col-4{
    background-image: url('../../media/images/ServicesBanner/kynnet.jpg');
}

.sb-col-5{
    background-image: url('../../media/images/ServicesBanner/ripsetjakulmat.jpg');
}

.sb-col-6{
    background-image: url('../../media/images/ServicesBanner/hiushoidot.jpg');
    background-position: center;
}

.serviceBanner-col-img{
    background-size: cover;
    margin: 2vh 1vw;
    border-radius: 5%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-shadow: 3px 1px #000000;
    text-align: center;
}

.serviceBanner-col-img:hover{
    filter: brightness(0.5);
    transition: all linear 0.2s;
    cursor: pointer;
}
.serviceBanner-text{
    margin: 3vh 0;
}

#serviceBanner-HH{
    color: rgba(0, 0, 0, 0.541);
    text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255), 1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
    font-size: 2rem;
}
#servicesBanner-title{
    font-size: 4vw;
    font-weight: 300;
    font-family: 'Belleza', sans-serif
}
#servicesBanner-description{
    color: gray;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: lighter;
}
#servicesBanner-btn{
    border-radius: 0;
}




@media only screen and (max-width: 992px){
    #servicesBanner-description{
        font-size: 2rem;
        line-height: 2.5rem;
    }
    #servicesBanner-title{
        font-size: 1.5rem;
        font-weight: 300;
        font-family: 'Belleza', sans-serif
    }
}

@media only screen and (max-width: 767px){
    #servicesBanner-description{
        font-size: 1rem;
        line-height: 1.5rem;
    }
    #servicesBanner-title{
        font-size: 1.5rem;
        font-weight: 300;
        font-family: 'Belleza', sans-serif
    }
}
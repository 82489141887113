#booking-banner-box{
    background: url('../../media/images/booking/booktime_banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
}


#book-info-box{
    background-color: rgba(255, 255, 255, 0.849);
    box-shadow: 0 5px 10px 0 black;
    padding: 3vh;
    font-size: 2vh;
    margin-top: 3vh;
}
#book-info-box:hover{
    transform: scale(1.01);
    transition: all 0.4s linear;
}

#booking-title{
    font-size: 3rem;
    margin: 2vh 0;
    font-family: 'Belleza', sans-serif
}

#booking-description{
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: lighter;
    margin: 2vh 0;
}

#booking-btn{
    float: right;
    background-color: black;
}
#booking-btn:hover{
    background-color: gray;
    border-color: black;
}

@media only screen and (max-width: 992px){
    #booking-title{
        font-size: 3rem;
        margin: 2vh 0;
        font-family: 'Belleza', sans-serif
    }

    #book-info-box{
        width: 80%;
        margin-left: 5vw;
    }

    #booking-description{
        font-size: 1.8rem;
        line-height: 2.5rem;
        font-weight: lighter;
        margin: 2vh 0;
        background-color: rgba(255, 255, 255, 0);
    }
}

@media only screen and (max-width: 768px){
    #booking-title{
        font-size: 2.5rem;
        margin: 2vh 0;
        font-family: 'Belleza', sans-serif
    }

    #book-info-box{
        width: 80%;
        margin-left: 5vw;
    }

    #booking-description{
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-weight: lighter;
        margin: 2vh 0;
        background-color: rgba(255, 255, 255, 0);
    }

}

@media only screen and (max-width: 575px){
    #booking-title{
        font-size: 2rem;
        margin: 2vh 0;
        font-family: 'Belleza', sans-serif
    }

    #book-info-box{
        width: 80%;
        margin-left: 5vw;
    }

    #booking-description{
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: lighter;
        margin: 2vh 0;
        background-color: rgba(255, 255, 255, 0);
    }
}



@media only screen and (min-width: 1200px){
    #booking-banner-box{
        margin-top: 15vh;
    }
}

@media only screen and (orientation: portrait) {
	#booking-banner-box {
		background: url("../../media/images/booking/booktime_banner.jpg");
		background-position: 30% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 70vh;
	}
}
.new-products-name{
	text-align: center;
	margin: 5vh 0;
}

.product-div-upper{
	margin: 5vh 0;
}

.list-span{
	display: inline-block;
	margin: 4px auto;
}

.first-span{
	display: inline-block;
	margin-bottom: 30px;
}

.new-products-pic{
	max-height: 50vh;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.lower-pics-div{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	margin-bottom: 5vh;
}

.lower-pic{
	width: 50%;
	max-height: 50vh;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.player-wrapper {
	position: relative;
	width: 40vw;
	height: 50vh;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

/*XXS screen*/
@media only screen and (max-width: 300px) {
	.product-div-upper{
		margin: 0;
	}

	.new-products-pic{
		width: 90%;
		margin: 1vh 5%;
	}

	.player-wrapper{
		width: 50vw;
		margin: 3vh 25vw;
	}

	.lower-pic{
		width: 80%;
		margin: 10% 10%;
	}
}

/*XS SCREEN*/
@media only screen and (min-width: 301px) and (max-width: 576px) {
	.product-div-upper{
		margin: 0;
	}

	.new-products-pic{
		width: 90%;
		margin: 1vh 5%;
	}

	.player-wrapper{
		width: 50vw;
		margin: 3vh 25vw;
	}

	.lower-pic{
		width: 80%;
		margin: 10% 10%;
	}
}
	
/*MEDIUM SCREENS*/
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.player-wrapper{
		width: 50vw;
		margin: 3vh 25vw;
	}

	.lower-pic{
		width: 80%;
		margin: 10% 10%;
	}
}


.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#carousel {
  width: 80%;
}
.carousel {
  height: 100%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

#slider {
  padding-bottom: 0;
  margin-top: 0;
}

.carousel-pic {
  width: 100%;
  float: right;
}

.carousel-pic-box {
  background: radial-gradient(
    rgba(241, 90, 165, 0.315),
    rgba(230, 108, 108, 0.582)
  );
}

#slide-topLogo {
  width: 10%;
  margin: 0 45%;
  margin-bottom: 6vh;
}

.caption-info-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(57, 56, 57, 0.484);
  border-radius: 10px;
  padding: 5vh 5vw;
}
.slider-hanna-book-btn {
  width: 50%;
  right: 0;
  bottom: 80%;
  position: absolute;
}

@media only screen and (orientation: landscape) {
  #rekry {
    content: url("../../media/images/slider/rekry.png");
  }
  #bjork-products {
    content: url("../../media/images/foto4.jpg");
  }
  #davines-products {
    content: url("../../media/images/davinesHorizontal.png");
  }
  #hanna-new-worker {
    content: url("../../media/images/slider/hannaHorizontal.png");
  }
}
@media only screen and (orientation: portrait) {
  #rekry {
    content: url("../../media/images/slider/rekryVertical.png");
  }
  #bjork-products {
    content: url("../../media/images/foto4.png");
  }
  #davines-products {
    content: url("../../media/images/davines.jpeg");
  }
  #hanna-new-worker {
    content: url("../../media/images/slider/hannaVertical.png");
  }
}

@media only screen and (max-width: 992px) {
  #slider {
    margin-top: 0;
  }

  #slide-topLogo {
    width: 40%;
    margin: 0 30%;
    margin-bottom: 6vh;
  }
  #carousel {
    width: 100%;
  }
  .carousel {
    height: 45vh;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-top: 0vh;
    padding-top: 0;
    margin-bottom: 5vh;
  }

  .carousel h3 {
    font-size: 1rem;
  }

  .slide-description {
    font-size: 0.8rem;
    text-shadow: 2px 1px black;
  }

  .slide-btn {
    font-size: 0.5rem;
  }

  .carousel-pic {
    height: 45vh;
    width: 100%;
  }

  .slider-hanna-book-btn {
    width: 100%;
    right: 0;
    bottom: -15%;
    position: relative;
  }
}

@media only screen and (max-width: 576px) {
  .slider-hanna-book-btn {
    width: 100%;
    padding: 1px;
    right: 0;
    bottom: -15%;
    position: relative;
  }
}

@media only screen and (min-width: 1400px) {
  .carousel {
    width: 80%;
    margin: 0 8vw;
  }
}


.service-col{
    position: relative;
    margin: 3vh 1vw;
    min-height: 45vh;
    cursor: pointer;
}


.service-col:hover{
    transform: scale(1.02);
    transition: all 0.2s linear;
}

.overlay-services-box{
    background-color: hsla(0,0%,8%,.519);
    height: 100%;
    width: 100%;
}

.overlay-services-box:hover{
    background-color: rgba(104, 104, 104, 0.832);
    transition: all 0.2s linear;
}

.services-row{
    margin: 3vh 0;
    display: flex;
    justify-content: center;
}

.services-btn{
    background-color: black;
    margin-top: 3vh;
    border-color: black;
}
.services-btn:hover{
    background-color: rgb(102, 97, 97);
    border-color: black; 
}

.services-info{
    font-size: 2vw;
    color: rgb(159, 153, 153);
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
    filter: brightness(2);
    z-index: 2;
}

#service-team-info{
	position: absolute;
	max-width: 100%;
	left: 5%;
	top: 25%;
}
#service-team-btn{
	position: absolute;
	left: 50%;
	top: 50%;
}
#service-gallery-info{
	position: absolute;
	max-width: 80%;
	left: 20%;
	top: 20%;
}

#service-gallery-btn{
    position: absolute;
    top: 60%;
    left: 40%;
}

#service-new-btn{
	position: absolute;
	left: 55%;
	bottom: 10%;
}

#service-new{
	position: absolute;
	left: 8%;
	top: 18%;
	width: 92%;
}

#service-new-span{
	font-size: 1.5vw;
}

@media only screen and (min-width: 301px) and (max-width: 576px) {
	.services-info{
		font-size: 1.5rem;
	}

	#service-new-info{
		position: absolute;
		left: 8%;
		top: 0%;
	}

	#service-new-span{
		font-size: 20px;
	}

	#new-title{
		margin-bottom: 4vh;
		font-size: 1.5rem;
	}
}

@media only screen and (min-width: 577px) and (max-width: 992px){
	.services-info{
		text-align: left;
		position: relative;
		font-size: 1.5rem;
		line-height: 2.3rem;
	}

	.service-col{
		position: relative;
		margin: 2vh 1vw;
		min-height: 30vh;
		cursor: pointer;
	}

	.services-btn{
		margin-bottom: 3vh;
	}

	#service-new-info{
		position: absolute;
		left: 8%;
		top: 0%;
	}

	#service-new-span{
		font-size: 20px;
		line-height: 0;
	}

	#new-title{
		margin-bottom: 2vh;
	}

	#service-new-btn{
		bottom: 3%;
	}
}

@media only screen and (min-width: 1200px){
    .services-container{
        margin-top: 15vh;
    }
}

@media only screen and (max-width: 300px){
	.services-info{
		text-align: left;
		position: relative;
		font-size: 1rem;
		line-height: 1.5rem;
		margin-top: 3vh;
	}

	#service-new{
		position: absolute;
		left: 8%;
		top: 10%;
		width: 92%;
		font-size: 20px;
	}

	#service-new-btn{
		position: absolute;
		left: 55%;
		bottom: 10%;
	}

	#new-title{
		margin-bottom: 5vh;
	}

	#service-new-span{
		font-size: 10px;
	}
	
}

.box-meetUs{
    background-image: url('../../media/images/ruPlace.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.box-gallery{
    background-image: url('../../media/images/frontpage_4_squares/gallery4.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.box-services{
    background-image: url('../../media/images/frontpage_4_squares/DSC05499.jpg');
    background-repeat: repeat;
    background-size: cover;
	background-position: 50%;
}

.box-4{
    background-image: url('../../media/images/slider/promo_horizontal.png');
    background-repeat: no-repeat;
    background-size: cover;
}

#service-new-span{
	font-size: 16px;
}
.frontpage-container{
    min-height: 100vh;
    background: url('../../media/images/General/front_page_pic.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
}

.container-page {
	overflow: hidden;
    padding-bottom: 20vh;
    padding-top: 5vh;
    position: relative;
    top: 10vh;
}

.frontpage-txt-box{
    margin: 0 10%;
	position: relative;
}

#frontpage-subtitle, .frontpage-title {
    color: #fff;
    font-weight: 100;
    letter-spacing: 6px;
    text-shadow: 5px 1px #000;
    z-index: 99;
}

.frontpage-title{
    margin-top: 15vh;
    font-size: 5rem;
}

#frontpage-subtitle{
	font-size: 2rem;
	margin-top: 6vh;
}

.frontpage-btn{
    border-radius: 0;
    letter-spacing: 2px;
    font-size: 1.5rem;
    width: auto;
    margin:0.67em;
    z-index: 99;
    color:white;
    border-color: white;
    transition: all 0.3s linear;
	background-color: hsla(0,18%,81%,.242);
}

.frontpage-btn:hover{
    background-color: white;
    color: black;
}

.frontpage-btn:active{
    position: relative;
    top: 2px;
}

@media only screen and (max-width: 400px){
	.frontpage-btn, .frontpage-title {
		left: 20vw;
		position: relative;
	}
	.frontpage-title {
		font-size: 2.8rem;
	}
	#frontpage-subtitle {
		font-size: 2rem;
		left:0;
		position: relative;
	}
}


@media only screen and (max-width: 768px){
    .frontpage-title{ 
        font-size: 3.5rem;
		left: 20vw;
		position: relative;
    }
    .frontpage-title span{
        font-size: 1.3rem;
        width: 100%;
    }
	#frontpage-subtitle{
		left: 10vw;
		position: relative;
	}

    .frontpage-container{
        min-height: 100vh;
        background: url('../../media/images/General/front_page_pic_vertical.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 65% center;
    }
	.frontpage-btn {
		font-size: 1.8rem;
		left: 30%;
		position: relative;
	}
}

@media only screen and (min-width: 1200px){
	#frontpage-subtitle, .frontpage-btn, .frontpage-title {
		left: 35vw;
		position: relative;
	}
	.frontpage-btn {
		left: 60%;
	}
}


@media only screen and (orientation: portrait) {
	.frontpage-container {
		background: url("../../media/images/General/front_page_pic_vertical.jpg");
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
	}
}

#meetus-pic-col{
	margin-bottom: 4vh;
}

.meetUs-pic{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 black;
}

.meetUs-worker-col{
    margin-bottom: 10vh;
    padding: 10vh 0;
    z-index: 2;
}

.meetUs-cont{
    padding-left: 10%;
    padding-right: 10%;
}

.meetUs-row{
   padding-top: 10vh;
}


.workers-pic{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.493);
}

.worker-name{
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: 2vh;
    margin-top: 4vh;
    color: rgba(0, 0, 0, 0.603);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.worker-xp{
    font-size: 1.3rem;
    text-align: center;
    font-weight: 300;
    margin-top: 2vh;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.worker-description{
    text-align: center;
    font-size: 1.3rem;
    font-weight: 100;
    margin-top: 5vh;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 1px 1px #565050;
    background-color: rgba(255, 255, 255, 0.493);
    border-radius: 10px;
    border: 0.5px solid rgba(47, 45, 45, 0.584);
    padding: 1vh 1vw;
}
.meetUs-description-flex{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.meetUs-worker-row{
    padding: 10vh 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url("../../media/images/meetUs-background.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
}


.meetUs-worker-row-overlay{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.727);
}

.meetUs-titles{
    text-align: center;
    font-weight: 400;
    font-size: 5rem;
    letter-spacing: 1px;
    z-index: 2;
}

.socialMedia-description-text-logo{
    width: 25px;
}

.socialMedia-description-text-logo:hover{
    transform: scale(1.1);
}

.book-hanna {
	font-size: 1rem;
	font-style: italic;
}
@media only screen and (max-width: 768px){
    .meetUs-worker-row{
        padding-left: 10%;
        padding-right: 10%;
    }
    .meetUs-worker-col{
        margin: 2vh 0;
        padding: 3vh 0;
    }
    .meetUs-titles{
        font-size: 2rem;
    }
}

.lng-container{
    display: flex;
    align-items: center;
}

.lng-flags{
     width: 25px;
     margin: 0 3px;
     vertical-align: baseline;
}

.lng-flags:hover{
    border-bottom: 2px solid rgba(32, 30, 30, 0.699);
}

@media only screen and (min-width: 900px){
    .nav-bar{
        height: 10vh;
        box-shadow: 0 5px 4px 0 rgba(0,0,0,.2)
    }
}

@media only screen and (max-width: 600px){
    .lng-flags{
        width: 40px;
        margin: 0 3px;
        vertical-align: baseline;
   }
}
#navbar-logo{
    height: 10vh;
}
.socialMedia-logo{
    height: 30px;
    float: right;
}




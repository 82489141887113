
.service-picture{
    width: 100%;
}

@media only screen and (max-width: 767px){
    #service-list-names{
        display: none;
    }
}

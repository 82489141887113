.individual-service-img{
    width: 100%;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
	border-radius: 5px;
}

.individual-service-title{
    text-align: center;
    margin-top: 4vh;
    letter-spacing: 1vh;
}

.service-back-btn{
    width: 5vh;
    cursor: pointer;
}
.service-back-btn:hover{
    transform: scale(1.05);
    transition: all 0.2s linear;
}

.button-service-sm{
	margin: 1vh 0;
	display: flex;
	align-items: center;
}

.indService-pics{
	padding: 10vh;
}

.service-pic-list{
    width: 100%;
    margin: 1vh;
	box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
	border-radius: 5px;
}

#accordion-header{
    color: gray;
}

.ind-service-menu{
    font-size: 1.2rem;
}

@media only screen and (max-width: 1000px){
    .indService-pics{
        padding: 0;
        margin: 1vh
    }
}

@media only screen and (max-width: 767px) {
    .individual-service-title{
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .individual-service-title{
        text-align: center;
        margin-left: 1.5em;
        margin: auto 20%;
    }
    .individual-service-info{
        width: 100%;
        margin: auto 20%;
    }
}
#contacts-container{
    background: radial-gradient(rgb(48, 45, 45), black);
    margin-bottom: 10vh;
}

#contactMe{
    font-size: 6vh;
    font-weight: bold;
    margin: 15px 0;
    color: white;
}

#contactDescription{
    font-size: 3vh;
    margin: 30px 0;
    color: white;
}

.contacts-info-box{
    margin: 2vh 0;
    color: white;
    font-size: 3vh;
    display: flex;
    align-items: center;
}
.contacts-info-title{
    font-weight: bold;
}
.contacts-info-detail{
    font-weight: 100;
}

.contacts-icons{
    width: 65px;
}

#adress-icon{
    position: relative;
    top: -20px;
}
@media only screen and (max-width: 992px){
    #contacts-container{
        margin-bottom: 5vh;
    }

    .contacts-info-box{
        text-align: center;
    }

    #contactMe{
        text-align: center;
    }
    #adress-icon{
        position: relative;
        top: 0px;
    }
    
}